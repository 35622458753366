<template>
  <div class="ServiceHall">
    <!-- 服务大厅 -->
    <div class="Hall">
      <div class="Hall-head">
        <div class="Hall-title">推荐服务商</div>
        <div class="Hall-delete select" @click="HallDelete">
          <img src="@/assets/imgs/delete.png" alt class="Hall-deleteimg" />
          <div>清空筛选条件</div>
        </div>
      </div>
      <div class="Hall-list">
        <!--  -->
        <div class="Hall-items" v-if="Platforms.Popular">
          <div class="Hall-list-title">热门服务:</div>
          <div class="flex-wrap">
            <div class="items items-border" :class="
                ServiceHallConfig.itemsId == item.id ? 'items-active' : ''
              " v-for="(item, index) in Platforms.Popular" :key="index" @click="PopularSwich(item)">
              <div>{{ item.itemsName }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 筛选 排序 -->
    <div class="Sort">
      <div class="Sort-info">
        <div class="items" v-for="(item, index) in Sort" :key="index" :class="SortIndex == index ? 'items_active' : ''" @click="SortSwich(index)">
          {{ item.name }}
          <i v-if="item.icon" class="iconfont icon-jiantou-xia-cuxiantiao"></i>
        </div>
      </div>
    </div>
    <div class="Lists" id="Lists" v-loading="loading" element-loading-text="拼命加载中...">
      <!-- 列表 -->
      <div class="listss">
        <provider-item v-for="(item, index) in ServiceHallList" :key="index" :items="item" :Show="false" @GetClick="GetDetailsPage"></provider-item>
      </div>
      <!-- 暂无信息 -->
      <el-empty v-show="ServiceHallempty" :image="NoList">
        <div class="empty">
          <!-- <el-button type="danger">刷新一下 试试</el-button> -->
        </div>
      </el-empty>
      <!-- 分页 -->
      <!-- <Pagination :total="total" :size="ServiceHallConfig.numPerPage" :page="ServiceHallConfig.pageNum" @handlePage="PageChange" v-if="total > ServiceHallConfig.numPerPage" /> -->
    </div>
  </div>
</template>
<script>
// import Pagination from "@/components/Pagination";

//  list 后续做 loading 加载
import ProviderItem from "@/components/List/components/providerItem.vue";

export default {
  data() {
    return {
      Sort: [
        {
          name: "综合排序",
          type: "all",
        },
        {
          name: "价格",
          icon: true,
          type: "asc",
        },
        {
          name: "销量",
          icon: true,
          type: "desc",
        },
      ],
      SortIndex: 0, //默认下标 为 0
      IndustryList: [],
      CategoryList: [],
      // 需求大厅数据
      ServiceHallList: [],
      total: 0, //需求大厅   总数据/条
      last_page: 1, //需求大厅 总页面
      ServiceHallConfig: {
        pageNum: 1,
        numPerPage: 60,
        platformId: null,
        itemsId: null,
        providerCity: null,
        categoriesId: null,
        serviceTitle: null,
      },
      // end
      // 平台信息
      Platform: {
        PlatformList: [],
        CityList: [],
        ServiceType: [],
      },
      Platforms: {
        Domestic: [],
        Abroad: [],
        Popular: [],
        All: [],
      },
      AllList: [],
      //   加载
      loading: true,
      ServiceHallempty: false,

      platList: [],
      Popover2Show: true,
      NoList: require("@/assets/imgs/no_hall.png"),
    };
  },
  components: {
    ProviderItem
    // Pagination,
  },
  created() {
    this.ConfigCount();
    this.GetPopularService();
  },
  mounted() {},
  methods: {
    ConfigCount() {
      this.GetServiceHall();
    },
    SortSwich(index) {
      this.SortIndex = index;
      this.ResettingHall();
      // 调取服务大厅的数据
      this.GetServiceHall();
    },
    // 获取 热门服务
    GetPopularService() {
      this.$axios
        .get(`/app-api/hiring/service-items/recommend`, {
          params: {
            num: 6
          }
        })
        .then((res) => {
          if (res.data.code === 0) {
            let data = res.data.data;
            for (let x = 0; x < data.length; x++) {
              if (data[x].children) {
                for (let y = 0; y < data[x].children.length; y++) {
                  if (
                    data[x].children[y].pid != 0 &&
                    data[x].children[y].recommend == 1
                  ) {
                    this.Platforms.Popular.push(data[x].children[y]);
                  }
                }
              }
            }
            // this.Platforms.Popular = res.data.data;
          } else {
            this.Platform.Popular = [];
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // 重置数据
    ResettingList(data) {
      let arr = [];
      for (let x in data) {
        let obj = {
          id: x,
          name: data[x],
        };
        arr.push(obj);
      }
      return arr;
    },
    // 重置需求大厅参数
    ResettingHall() {
      this.ServiceHallConfig = {
        pageNum: 1,
        numPerPage: 60,
        platformId: null,
        itemsId: null,
        providerCity: null,
        categoriesId: null,
        serviceTitle: null,
      };
      this.ServiceHallList = [];
    },
    SortChage() {
      // 清除数据
      // this.ResettingHall()
      this.GetServiceHall();
    },
    // 页面切换
    PageChange(val) {
      // 跳转到顶部
      let targetEle = document.querySelector("#Lists");
      window.scrollTo({
        top: targetEle.offsetTop,
        // behavior: "smooth",
      });
      this.ServiceHallConfig.pageNum = val;
      this.GetServiceHall();
    },
    SwichPopover(item) {
      this.ResettingHall();
      this.ServiceHallConfig.platformId = item.id;
      //
      this.GetServiceHall();
    },
    // 获取服务大厅的数据
    GetServiceHall() {
      let url = `/app-api/hiring/service-provider-info/hall?pageNo=${this.ServiceHallConfig.pageNum}&pageSize=${this.ServiceHallConfig.numPerPage}`;
      // 当拿到的不是综合排序
      if (this.SortIndex !== 0) {
        url +=
            this.SortIndex === 1
                ? `&priceDesc=true`
                : `&orderDesc=true`;
      }
      // 是否有选择筛选条件
      if (
        this.ServiceHallConfig.platformId ||
        this.ServiceHallConfig.itemsId ||
        this.ServiceHallConfig.providerCity ||
        this.ServiceHallConfig.categoriesId
      ) {
        // 平台
        if (this.ServiceHallConfig.platformId) {
          url += `&platformId=${this.ServiceHallConfig.platformId}`;
        }
        // 所在地区
        if (this.ServiceHallConfig.providerCity) {
          url += `&providerCity=${this.ServiceHallConfig.providerCity[1]}`;
        }
        // 服务类目
        if (+this.ServiceHallConfig.categoriesId) {
          // 处理数据 platformId
          url += `&categoriesId=${this.ServiceHallConfig.categoriesId}`;
        }
        // 服务项目
        if (this.ServiceHallConfig.itemsId) {
          // 处理数据 platformId
          url += `&itemsId=${this.ServiceHallConfig.itemsId}`;
        }
        if (this.ServiceHallConfig.itemsPId) {
          url += `&itemsPId=${this.ServiceHallConfig.itemsPId}`;
        }
      }
      this.loading = true;
      this.$axios
        .get(url)
        .then((res) => {
          if (res.data.code === 0) {
            // console.log(res);
            this.loading = false;
            // if (res.data.data.total == 0) {
            //   this.ServiceHallempty = true;
            //   this.total = res.data.data.total;
            //   return (this.ServiceHallList = []);
            // }
            this.ServiceHallempty = false;
            this.ServiceHallList = res.data.data.list;
            this.total = res.data.data.total;
            // this.last_page = res.data.data.pre_page;
          }
        })
        .catch((err) => {
          this.loading = false;
          return Promise.reject(err);
        });
    },

    // 进入详情页面
    GetDetailsPage(Ids) {
      this.$router.push({ path: "/ServiceHall/Details", query: { id: Ids } });
    },
    // 清除筛选信息
    HallDelete() {
      // this.$modal.msgSuccess("清除筛选成功!");
      this.ServiceHallList = [];
      this.ServiceHallConfig = {
        pageNum: 1,
        numPerPage: 60,
        platformId: null,
        itemsId: null,
        providerCity: null,
        categoriesId: null,
        serviceTitle: null,
      };
      this.$refs.Popover.Config.value = "";
      this.$refs.Popover.Config.id = "";
      this.$refs.Popover2.Config.value = "";
      this.$refs.Popover2.Config.id = "";
      this.$refs.Popover2.Config.ids = "";
      // 进行筛选数据
      this.GetServiceHall();
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-input__inner {
  height: 47px !important;
}
.Lists {
  min-height: 400px;
  .items {
    margin-right: 31px !important;
    &:nth-child(5n) {
      margin-right: 0 !important;
    }
    &:nth-child(n + 6) {
      margin-top: 40px !important;
    }
  }
}
.ServiceHall {

  .Hall {
    background: #ffffff;

    .Hall-head {
      height: 69px;
      //
      width: 100%;
      padding: 0 28px;
      box-sizing: border-box;
      border-bottom: 1px solid #dfe3ea;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .Hall-title {
      color: #000000;
      font-size: 20px;
      line-height: 87px;
      font-weight: 700;
    }
    .Hall-delete {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 20px;
    }
    .Hall-delete .Hall-deleteimg {
      width: 18px;
      height: 20px;
      margin-right: 4px;
    }
    .Hall-delete div {
      font-weight: 400;
      color: #333333;
      line-height: 23px;
      font-size: 16px;
    }

    .Hall-list {
      padding: 0 28px 28px;
      box-sizing: border-box;

      .Hall-items {
        display: flex;
        // align-items: center;
        margin-top: 24px;
      }

      .Hall-list-title {
        color: #999999;
        font-size: 20px;
        min-width: 110px;
        line-height: 58px;
      }

      .flex-wrap {
        display: flex;
        flex-wrap: wrap;
        margin-left: 13px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .items-border {
      border: 1px solid #e8e8e8;
    }
    .items {
      width: 161px;
      height: 58px;
      border-radius: 4px 4px 4px 4px;
      box-sizing: border-box;
      margin-right: 12px;
      cursor: pointer;
      &:nth-child(7n) {
        margin-right: 0 !important;
      }
      &:nth-child(n + 8) {
        margin-top: 20px;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        border: 1px solid #fc3737;
        // box-sizing: border-box;
        div {
          font-weight: 700;
        }
      }
    }
    .items-active {
      border: 1px solid #fc3737;
      // box-sizing: border-box;
      div {
        font-weight: 700;
      }
    }
    .items-center {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .Sort-info {
      display: flex;
      align-items: center;
      margin-top: 24px;

      .Sort-menu {
        display: flex;
        align-items: center;
        margin-left: 13px;

        // justify-content: space-between;
        .Menu {
          margin-right: 12px;
          width: 154px;
          height: 47px;
        }

        /deep/ .el-dropdown {
          width: 100%;
          height: 100%;
        }

        .Name {
          color: #999999;
          font-size: 20px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;

          .select {
            display: block;
          }

          i {
            display: block;
            // font-size: 25px !important;
          }
        }
      }
    }
  }

  .Sort {
    // position: sticky;
    width: 100%;
    // background: #ffffff;
    height: 69px;
    top: 88px;
    z-index: 88;
    margin-top: 30px;

    .Sort-info {
      margin: 0 auto;
      width: 1440px;
      display: flex;
      align-items: center;
      height: 100%;
      background: #ffffff;

      .items {
        color: #999999;
        font-size: 20px;
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 30px;
        cursor: pointer;
        margin-right: 12px;

        &:hover {
          color: #ffffff;
          background: #fc3737;

          i {
            color: #ffffff !important;
          }
        }

        img {
          height: 17px;
          margin-left: 4px;
        }

        i {
          margin-left: 4px;
          font-size: 25px;
          color: #999999;
        }
      }

      .items_active {
        color: #ffffff;
        background: #fc3737;

        i {
          color: #ffffff !important;
        }
      }
    }
  }

  .Lists {
    width: 1440px;
    margin: 30px auto 0;

    display: flex;
    flex-wrap: wrap;
    .listss {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
</style>
