<template>
  <div class="items" :class="Num === 5 ? 'items_num' : 'items_num4'">
    <el-avatar :size="93" :src="item.user.avatarImg"></el-avatar>
    <div class="title">{{item.providerName}}</div>
    <div class="com-data">
      <div class="types" v-if="item.providerType === 1">个人</div>
      <div class="type" v-if="item.providerType === 2">企业</div>
      <div class="bzj">保证金{{item.providerType === 1?'1000':'2000'}}</div>
      <div class="city">{{item.address}}</div>
    </div>
    <div class="com-info">
      <div class="num">80人购买</div>
      <div class="cort">好评率{{item.responseRate == 0 ? 100 : item.responseRate+'%'}}</div>
    </div>
    <div class="submit">
      <div class="zixun" @click.stop="clickShowChat">在线咨询</div>
      <div class="zhuye" @click="GetClick(item.userId)">进入主页</div>
    </div>
  </div>
</template>
<script>
// list1 需要判断排版数量
import { mapActions } from "vuex";

export default {
  name: 'provider-item',
  props: {
    items: {
      type: Object,
      default: () => {
        return {};
      },
    },
    Num: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      id: "",
      userId: "",
    };
  },
  mounted() {
    this.userId = this.$store.getters.getUser.id;
  },
  computed: {
    item() {
      let ret = this.items
      let cityList = this.$store.getters.getCity;
      if (cityList && cityList.length) {
        let tmp = cityList.find(city => city.id === ret.providerProvince)
        if (tmp) ret.provinceName = tmp.name
        let cityTmp = tmp.children.find(city => city.id === ret.providerCity)
        if (cityTmp) ret.cityName = cityTmp.name
        ret.address = ret.provinceName + '-' + ret.cityName
      }
      return ret
    }
  },

  methods: {
    ...mapActions(['showChat']),
    GetClick(Ids) {
      this.$router.push({ path: "/Service/Home", query: { id: Ids } });
    },
    clickShowChat() {
      if (!this.$store.getters.getUser || !this.$store.getters.getUser.id) {
        this.$notify.warning('您还未登录，请先登录')
        return
      }
      if (this.items.user.userPhone === this.$store.getters.getUser.userPhone) {
        this.$notify.info('您不能向自己在线咨询！')
        return
      }
      this.showChat({
        show: true,
        phone: this.items.user.userPhone,
        goods: {
          id: '',
          name: '',
          desc: '',
          img: ''
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.items {
  width: 260px;
  height: 276px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  margin-right: 30px;
  border: 1px solid #ddd;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  .title {
    font-weight: 400;
    color: #000000;
    line-height: 14px;
    font-size: 12px;
    margin-top: 20px;
  }
  .com-data {
    margin-top: 10px;
    display: flex;
    .type {
      width: 30px;
      height: 17px;
      background: #3d8bff;
      border-radius: 2px;
      font-weight: 400;
      color: #ffffff;
      line-height: 17px;
      font-size: 12px;
      text-align: center;
    }
    .types {
      width: 30px;
      height: 17px;
      background: #f46700;
      border-radius: 2px;
      font-weight: 400;
      color: #ffffff;
      line-height: 17px;
      font-size: 12px;
      text-align: center;
    }
    .bzj {
      width: 71px;
      height: 17px;
      background: #36a04c;
      border-radius: 2px;
      font-weight: 400;
      color: #ffffff;
      line-height: 17px;
      font-size: 12px;
      margin-left: 13px;
      text-align: center;
    }
    .city {
      font-weight: 400;
      color: #999999;
      line-height: 14px;
      font-size: 12px;
      margin-left: 10px;
    }
  }
  .com-info {
    display: flex;
    margin-top: 11px;
    div {
      font-weight: 400;
      color: #b18021;
      line-height: 14px;
      font-size: 12px;
    }
    .cort {
      margin-left: 10px;
    }
  }
  .submit {
    display: flex;
    justify-content: center;
    padding: 14px 0;
    border-top: 1px dashed #e2e2e2;
    margin-top: 16px;
    div {
      width: 80px;
      height: 30px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      line-height: 14px;
      font-size: 12px;
      &:nth-of-type(1) {
        color: #fc3737;
        background: #ffe6e6;
      }
      &:nth-of-type(2) {
        background: #fc3737;
        color: #ffffff;
        margin-left: 24px;
      }
    }
  }
}
.flex-warp {
  display: flex;
}
.items_num {
  &:last-of-type {
    margin-right: 0 !important;
  }
}

.items_num4 {
  &:nth-child(5n) {
    margin-right: 0 !important;
  }

  &:nth-child(n + 6) {
    margin-top: 40px !important;
  }
}
/deep/ .el-carousel__container {
  height: 276px !important;
}
</style>
